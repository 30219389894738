<template>
    <header class="aplayzmo_header">
        <nav>
            <ul class="aplayzmo_header_box">
                <li>
                    <a>
                        <img src="/media/images/icon_back_24.svg" @click="$emit('click-pre')" />
                    </a>
                </li>
                <li>
                    <h2 class="aplayzmo_header_title">
                        <slot name="header_title">스페이스 등록</slot>
                    </h2>
                </li>
                <li class="btn_close" @click="$emit('link-myspace')">
                    <img src="/media/images/icon_close_24.svg" />
                </li>
            </ul>
        </nav>
        <slot name="progressbar"> </slot>
    </header>
</template>
<script>
export default {
  name: 'MoSpaceHeader',
  emits: ['click-pre', 'link-myspace'],
  data () {
    return {};
  },

  mounted () {},

  methods: {}
};
</script>
<style scoped src="@/assets/css/common/moheader.css"></style>
